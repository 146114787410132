<template>
    <div class="about">
        <b-row class="support label section">
            <b-col>
                <b-img center src="img/icons/logo.png" alt="logo" class="logo"/>
            </b-col>
        </b-row>

        <b-row class="section">
            <b-col>
                <b-row class="support label">
                    <b-col cols="1"><b-img center src="img/icons/feedback_24px.png"/></b-col>
                    <b-col>
                        <div>{{ $t("about.support") }}</div>
                    </b-col>
                </b-row>
                <b-row class="support value">
                    <b-col offset="1">
                        <div>{{ customerEmail }}</div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row class="section">
            <b-col>
                <b-row class="version label">
                    <b-col cols="1"><b-img center src="img/icons/call_24px.png"/></b-col>
                    <b-col>
                       <div>{{ $t("about.phone") }}</div>
                    </b-col>
                </b-row>
                <b-row class="version value">
                    <b-col offset="1">
                        <div>{{ customerPhone }}</div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row class="section">
            <b-col>
                <b-row class="version label">
                    <b-col cols="1"><b-img center src="img/icons/copyright_24px.png"/></b-col>
                    <b-col>
                        <div>{{ $t("about.version") }}</div>
                    </b-col>
                </b-row>
                <b-row class="version value">
                    <b-col offset="1">
                        <div>Build 1.0</div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {CONFIG} from '../config.js';

export default {
        data: function() {
            return {
                customerEmail: CONFIG.customerEmail,
                customerPhone: CONFIG.customerPhone,
            }
        },
        mounted: function() {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$ebus.$emit("title:set", this.$t("menu.about"))
        },
        destroyed: function() {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$ebus.$emit("title:reset")
        }
    }
</script>

<style scoped lang="scss">
    @import 'src/scss/main';

    .section {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 30px;
    }

    .logo {
        padding-top: 30px;
        padding-bottom: 30px;
    }
</style>